import React from 'react';
import css from './AddToCartButton.module.css';
import { Button } from '..';
import { useConfiguration } from '../../context/configurationContext';

const AddToCartButton = props => {
  const {
    listingId,
    authorId,
    count,
    toggleCart,
    isListingPage,
    buttonLabel,
    cartLabel,
    isBooking = false,
    showProductOrderForm = true,
    isOwnListing = false,
    isLoading,
    isCartPage,
    removeButtonLabel,
    currentStock,
    quantity,
    allowOrdersOfMultipleItems = false,
    disabled,
  } = props;

  const isBrowser = typeof window !== 'undefined';

  if (isBooking || !showProductOrderForm) {
    return null;
  }

  const config = useConfiguration();

  const increaseCount = (quant = 1) => toggleCart(listingId, authorId, config, quant);
  const decreaseCount = (quant = -1) => toggleCart(listingId, authorId, config, quant);

  const isMaxItems = currentStock <= count;

  if (!isCartPage && (!count || (count === 0 && currentStock > 0))) {
    return (
      <Button
        onClick={() => {
          if (
            !isOwnListing &&
            ((allowOrdersOfMultipleItems && quantity !== undefined) || !allowOrdersOfMultipleItems)
          )
            increaseCount(quantity ? parseInt(quantity, 10) : 1);
        }}
        inProgress={isLoading}
        disabled={isLoading || disabled}
      >
        {buttonLabel}
      </Button>
    );
  } else if (isListingPage && count > 0) {
    return (
      <Button
        className={css.removeFromCartBtn}
        onClick={() => {
          if (!isOwnListing) decreaseCount(-1 * count);
        }}
        inProgress={isLoading}
        disabled={isLoading}
      >
        {removeButtonLabel}
      </Button>
    );
  } else {
    return (
      <div>
        {isListingPage && cartLabel}
        <div className={css.buttonContainer}>
          <Button className={css.smallButton} onClick={() => decreaseCount()}>
            -
          </Button>
          <span className={css.countContainer}>{count}</span>
          <Button
            className={css.smallButton}
            onClick={() => {
              increaseCount();
            }}
          >
            +
          </Button>
        </div>
      </div>
    );
  }
};

AddToCartButton.defaultProps = {
  isListingPage: false,
};

export default AddToCartButton;
